import React, { useState, useEffect } from 'react';

const Testimonial = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulating asynchronous data fetching
    const fetchData = () => {
      setTimeout(() => {
        const fakeData = [
          {
            name: 'Tahessen Bhadi',
            profession: 'CEO,Atharv Trading Education Services',
            comment: 'Gratitude for the invaluable IT solutions you\'ve provided, greatly enhancing our operations. Your expertise has been instrumental in our success.',
            image: 'img/client/Atharva.jpeg'
          },
          {
            name: 'Prashant Sanap',
            profession: 'CEO, DFront Advertisement LTD.',
            comment: 'Thank you for your outstanding IT solutions. Your expertise has greatly improved our system work efficiency.',
            image: 'img/client/dfront.jpeg'
          },
          
          {
            name: ' Balaji   Kharate ',
            profession: 'CEO, Digibrandzs Private Ltd.',
            comment: 'Grateful for the impactful IT solutions; your team\'s dedication has truly made a difference.Thank you for your transformative IT solutions; your expertise has revolutionized our operations.',
            image: 'img/client/digibrands.jpeg'
          },
      
          
          {
            name: ' CEO',
            profession: 'CKI Rubber Technologies (I) Private Ltd',
            comment: 'I\'m impressed by the responsiveness of your customer support team; they were quick to address my inquiries and provide helpful solutions.',
            image: 'img/client/cki.jpeg'
          },
     
          
    
        ];
        setTestimonials(fakeData);
        setLoading(false);
      }, 20); // Simulating 2 seconds delay
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
        {/* <div className="section-title text-center position-relative pb-3 mb-4 mx-auto" style={{ maxWidth: '600px' }}>
          <h5 className="fw-bold text-primary text-uppercase">Testimonial</h5>
          <h1 className="mb-0">What Our Clients Say About Our Digital Services</h1>
        </div> */}
        <div className=" row g-5 justify-content-center testimonial-carousel wow fadeInUp" data-wow-delay="0.6s">
          {testimonials.map((testimonial, index) => (
            <div key={index} className=" col-lg-4 col-md-6 testimonial-item bg-light my-4">
              <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img className="img-fluid rounded" src={testimonial.image} style={{ width: '60px', height: '60px' }} alt='img' />
                <div className="ps-4">
                  <h4 className="text-primary mb-1">{testimonial.name}</h4>
                  <small className="text-uppercase">{testimonial.profession}</small>
                </div>
              </div>
              <div className="pt-4 pb-5 px-5">
                {testimonial.comment}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
