
import React, { useState, useEffect } from 'react';

const Employee = () => {
    const [testimonials, setTestimonials] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulating asynchronous data fetching
        const fetchData = () => {
            setTimeout(() => {
                const fakeData = [
                    {
                        name: 'Gauri Birari',
                        profession: 'Front-end Web Developer & Designer',
                        comment: 'Reflecting on my journey, I am working as a front-end web developer at Innovative Solutions. It\'s already been a year since I joined here. When I joined here, I started from scratch, but now after 1 year, I am thrilled to be learning outside of my field here. I am pleased to have the chance to create a project from scratch. All my colleagues and especially Santosh sir raised me so well, and it was my best decision to join this company because I learned things I had never experienced before. I believe this is the turning point of my career, and thanks to all my colleagues and Santosh sir for making me the wonderful person I am today.',
                        image: 'team/gauri.jpg'
                    },
                    {
                        name: 'Sakshi Kadave',
                        profession: 'Backend Web Developer',
                        comment: 'I am working as a backend end developer at the Innovative Solutions, and I have been with this company for 1 year now, My experience with this company has been like my second home. All the people around me in the company, especially Santosh sir, who raised me up in all things that I even had no idea about before my colleagues taught me well, make joining this company the best decision I have ever made.',
                        image: 'team/sakshi.jpeg'
                    },
                    {
                        name: 'Gaurav Bhambere',
                        profession: 'APP Developer',
                        comment: 'As an app developer at Innovative Solutions, I\'ve had the privilege to contribute to some groundbreaking projects. My journey here has been incredibly rewarding, thanks to the supportive team and inspiring leadership. The collaborative environment fosters creativity and innovation, allowing me to push the boundaries of app development. Each day brings new challenges, but it\'s these challenges that drive me to grow and excel in my role.I\'m proud to be part of a team that\'s shaping the future of technology and look forward to continuing this journey of innovation together.',
                        image: 'team/GauravB.jpeg'
                    },
                    {
                        name: 'Ram Gutte',
                        profession: 'Data Analyst',
                        comment: 'As a data analyst at Innovative Solutions, I\'ve had the opportunity to delve into complex datasets and extract valuable insights that drive strategic decision-making. My role involves uncovering patterns, trends, and correlations that inform business strategies and enhance operational efficiency. The supportive work environment and access to cutting-edge tools have empowered me to tackle challenging projects with confidence. I\'m grateful for the opportunity to contribute to the company\'s success and am excited to continue leveraging data to drive growth and innovation.',
                        image: 'team/RamG.jpg'
                    }
                    ,
                    {
                        name: 'Ram Telange',
                        profession: 'Front-end Web Developer',
                        comment: 'In my role as a front-end web developer at Innovative Solutions, I\'ve had the privilege to craft immersive and intuitive user experiences that elevate our digital presence. Over the past year, I\'ve honed my skills in HTML, CSS, and JavaScript to create visually stunning and responsive web applications. Collaborating with our talented team, I\'ve had the chance to bring creative ideas to life and push the boundaries of innovation. The supportive culture and mentorship provided by my colleagues, especially Santosh sir, have been instrumental in my growth. Joining this company has been a pivotal moment in my career, and I look forward to continuing to deliver impactful solutions that delight our users and drive business success.',
                        image: 'team/profile.jpg'
                    }
                    , {

                        name: 'Vikas Warwatte',
                        profession: 'Full Stack Web Developer',
                        comment: 'As a full stack web developer at Innovative Solutions, I\'ve had the opportunity to work on diverse projects, from concept to deployment, leveraging a wide range of technologies. Over the past year, I\'ve contributed to building robust and scalable web applications, handling both front-end and back-end development with proficiency. My role has involved implementing innovative features, optimizing performance, and ensuring seamless integration across different layers of the stack. The collaborative environment and mentorship provided by my colleagues, particularly Santosh sir, have been invaluable in my professional development. Joining this company has allowed me to expand my skill set and take on new challenges, making it a rewarding journey. I\'m excited to continue delivering high-quality solutions that exceed client expectations and drive business growth.',
                        image: 'team/vikas.jpg'


                    }

                ];
                setTestimonials(fakeData);
                setLoading(false);
            }, 20); // Simulating 2 seconds delay
        };

        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container-fluid py-5 wow fadeInUp" >
            <div className="container py-5">
                {/* <div className="section-title text-center position-relative pb-3 mb-4 mx-auto" style={{ maxWidth: '600px' }}>
          <h5 className="fw-bold text-primary text-uppercase">Testimonial</h5>
          <h1 className="mb-0">Intern Testimonials: Insights into Our Digital Solutions</h1>
        </div> */}
                <div className=" row g-5 justify-content-center testimonial-carousel wow fadeInUp">
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className=" col-lg-6 col-md-2 testimonial-item bg-light my-1">
                            <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                                <img className="img-fluid rounded" src={testimonial.image} style={{ width: '70px', height: '80px' }} alt='img' />
                                <div className="ps-4">
                                    <h4 className="text-primary mb-1">{testimonial.name}</h4>
                                    <small className="text-uppercase">{testimonial.profession}</small>
                                </div>
                            </div>
                            <div className="pt-4 pb-5 px-3 text-justify">
                                {testimonial.comment}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}



export default Employee;
